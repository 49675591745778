import { json, LoaderFunction, type MetaFunction } from '@remix-run/node';
import { Link, useLoaderData } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import brand_food_01 from '@assets/images/brand_food_01.png';
import brand_food_02 from '@assets/images/brand_food_02.png';
import brand_food_03 from '@assets/images/brand_food_03.png';
import coop_01 from '@assets/images/coop_01.png';
import coop_02 from '@assets/images/coop_02.png';
import coop_03 from '@assets/images/coop_03.png';
import coop_04 from '@assets/images/coop_04.png';
import coop_05 from '@assets/images/coop_05.png';
import food_01 from '@assets/images/food_01.png';
import food_02 from '@assets/images/food_02.png';
import food_03 from '@assets/images/food_03.png';
import food_04 from '@assets/images/food_04.png';
import food_05 from '@assets/images/food_05.png';
import food_06 from '@assets/images/food_06.png';
import iconArrowRight24 from '@assets/images/icon-arrow-right-24.png';
import icon_1 from '@assets/images/icon_1.png';
import icon_2 from '@assets/images/icon_2.png';
import icon_3 from '@assets/images/icon_3.png';
import icon_L from '@assets/images/icon_L.png';
import icon_L_green from '@assets/images/icon_L_green.png';
import logo_up from '@assets/images/logo_up.png';
import news_01 from '@assets/images/news_01.png';
import prod_01 from '@assets/images/prod_01.png';
import prod_02 from '@assets/images/prod_02.png';
import prod_03 from '@assets/images/prod_03.png';
import prod_04 from '@assets/images/prod_04.png';
import prod_05 from '@assets/images/prod_05.png';
import prod_06 from '@assets/images/prod_06.png';
import prod_07 from '@assets/images/prod_07.png';
import Rectangle_17 from '@assets/images/Rectangle_17.png';
import Rectangle_18 from '@assets/images/Rectangle_18.png';
import Rectangle_19 from '@assets/images/Rectangle_19.png';
import scroll_down from '@assets/images/scroll_down.png';
import { WEB_HOST } from '@common/constants';
import { formatDate } from '@common/tranfer.utils';
import { getMainNews } from '@server/home/main/main.client.server';
import { MainNewsDTO } from '@server/home/main/main.models';
import { HomeMediaDTO } from '@server/home/media/media.models';

export const meta: MetaFunction = () => {
    return [{ title: '눌러밥 > 메인' }, { name: 'description', content: '[눌러밥] 메인 페이지' }];
};

export const loader: LoaderFunction = async ({ request }) => {
    const data = await getMainNews(request);

    return json({
        news: data.content,
    });
};

export default function Index() {
    const data = useLoaderData<typeof loader>() as {
        news: MainNewsDTO[];
    };

    console.log(data.news);

    return (
        <div className="content">
            <MainSlide />

            <section className="sub_sec">
                <div className="com_inner">
                    <div className="com_tit_con ">
                        <div className="com_tit_unit">
                            <p className="cate_1 green">Our Value</p>
                            <p className="tit_1 green fw600">상상력과 혁신을 통한 새로운 식문화의 리더</p>
                            <p className="body_2 gray_7">
                                눌러푸드는 창의적인 아이디어와 끊임 없는 혁신을 통하여 그전에 존재하지 않았던 새로운
                                식문화 창조하겠습니다.
                            </p>
                        </div>
                    </div>

                    <div className="point_list">
                        <div className="list">
                            <div className="unit">
                                <span className="point_img">
                                    <img className="img_obj" src={Rectangle_18} alt="" />
                                </span>
                                <div className="point_txt_con">
                                    <img className="point_icon" src={icon_1} alt="" />
                                    <p className="tit_2 gray_8 fw600">독창적인</p>
                                    <p className="tit_4 gray_5">Creative</p>
                                    <p className="body_2 gray_7">
                                        세상에서 가장 간편하고 맛있고 건강한 음식으로 세상에 기여합니다.
                                    </p>
                                </div>
                            </div>

                            <div className="unit reverse">
                                <span className="point_img">
                                    <img className="img_obj" src={Rectangle_19} alt="" />
                                </span>
                                <div className="point_txt_con">
                                    <img className="point_icon" src={icon_2} alt="" />
                                    <p className="tit_2 gray_8 fw600">간편한</p>
                                    <p className="tit_4 gray_5">Convenient</p>
                                    <p className="body_2 gray_7">
                                        빠르고 편리한 먹거리로, 바쁜 일상 속에서도 식사를 즐길 수 있도록 합니다.
                                    </p>
                                </div>
                            </div>

                            <div className="unit">
                                <span className="point_img">
                                    <img className="img_obj" src={Rectangle_17} alt="" />
                                </span>
                                <div className="point_txt_con">
                                    <img className="point_icon" src={icon_3} alt="" />
                                    <p className="tit_2 gray_8 fw600">신뢰할 수 있는</p>
                                    <p className="tit_4 gray_5">Creditable</p>
                                    <p className="body_2 gray_7">
                                        놀라운 상상력을 통한 가치 창출 및 새로운 식문화를 선도하는 글로벌 식품
                                        기업입니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sub_sec pd_0">
                <div className="brand_con">
                    <div className="unit brand_back_1 slide_unit sub_sec">
                        <div className="com_inner">
                            <div className="com_tit_con ">
                                <div className="com_tit_unit">
                                    <p className="cate_1 green">Our Brand</p>
                                    <p className="tit_1 green fw600 mb_0">눌러푸드 브랜드 이야기</p>
                                </div>
                            </div>

                            <div className="list">
                                <div className="item">
                                    <div className="list_con">
                                        <div className="brand_img_con">
                                            <img className="brand_img img_1 pc" src={brand_food_01} alt="" />
                                            <img className="brand_img img_1 mo" src={brand_food_02} alt="" />
                                            <div className="arrow_box mo">
                                                <button className="arrow prev">
                                                    <img className="arrow_img" src={icon_L} alt="" />
                                                </button>
                                                <button className="arrow next">
                                                    <img className="arrow_img" src={icon_L} alt="" />
                                                </button>
                                            </div>
                                            <div className="arrow_box pc">
                                                <button className="arrow prev">
                                                    <img className="arrow_img" src={icon_L_green} alt="" />
                                                </button>
                                                <button className="arrow next">
                                                    <img className="arrow_img" src={icon_L_green} alt="" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="txt_con">
                                            <p className="brand_tit green">세상에서 가장 간편한 밥!</p>
                                            <p className="tit_2 green">The easiest rice in the world!</p>
                                            <div className="txt_unit">
                                                <p className="txt green">
                                                    <em>눌러밥</em>은 튜브컵 타입의 용기를 손으로 눌러 다른 도구 없이
                                                    바로 먹을 수 있는 즉석 식품입니다. 밥뿐만 아니라, 면, 샐러드, 간식,
                                                    반찬, 양념 등 매우 <em>다양한 확장성을 가진 아이디어 상품</em>
                                                    입니다.
                                                </p>
                                                <p className="txt green">
                                                    <em>눌러밥</em>은 세계적인 먹거리 시장의 흐름에 맞춰 기획, 개발된
                                                    식품으로 낮은 가격, 적은 쓰레기 양, 한손으로 취식 가능, 손에 묻지
                                                    않는다는 장점을 가지고 있습니다.
                                                </p>
                                                <p className="txt green">
                                                    <em>눌러밥</em>은 국내 시장에 한정되지 않고, 세계 각 국의 다양한
                                                    현지 음식을 <em>눌러밥 시리즈</em>로 상품화 하여, 해외 시장으로
                                                    확장을 준비하고 있습니다.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="unit brand_back_2 sub_sec">
                        <div className="com_inner">
                            <div className="com_tit_con ">
                                <div className="com_tit_unit">
                                    <p className="cate_1 white">Our Brand</p>
                                    <p className="tit_1 white fw600 mb_0">눌러푸드 브랜드 이야기</p>
                                </div>
                            </div>

                            <div className="brand_img_con img_2">
                                <img className="brand_img" src={brand_food_03} alt="" />
                                <div className="arrow_box">
                                    <button className="arrow prev">
                                        <img className="arrow_img" src={icon_L} alt="" />
                                    </button>
                                    <button className="arrow next">
                                        <img className="arrow_img" src={icon_L} alt="" />
                                    </button>
                                </div>
                            </div>

                            <div className="txt_con txt_2">
                                <p className="brand_tit white">언제 어디서든 간편하게 눌러봐!</p>
                                <p className="tit_2 white">Push anytime, anywhere!</p>
                                <div className="txt_unit ty_2">
                                    <p className="txt white">
                                        언제 어디서나 간편하게 손에 들고 눌러서 먹을 수 있는 <em>눌러밥</em>은 시간과
                                        장소에 구애받지 않고 누구나 편리하게 즐길 수 있는 새로운 먹거리 혁신입니다.
                                    </p>
                                    <p className="txt white">
                                        <em>눌러밥</em>은 건강한 식재료와 맛있는 레시피를 바탕으로 만들어집니다. 약
                                        210여가지의 새로운 메뉴를 지속적으로 개발하고 있습니다.{' '}
                                    </p>
                                    <p className="txt white">
                                        <em>눌러밥</em>은 한 입 먹음으로 다양한 문화와 맛을 경험할 수 있는 창구입니다.
                                        글로벌 시장에서 다양한 문화를 반영한 눌러밥을 즐길 수 있습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sub_sec sht_b pd_a0">
                <div className="pd_05">
                    <div className="com_inner">
                        <div className="com_tit_con ">
                            <div className="com_tit_unit">
                                <p className="cate_1 green">Products</p>
                                <p className="tit_1 green fw600 mb_0">눌러밥 제품 안내</p>
                            </div>
                            <Link to="/product" className="btn_more com_btn">
                                <span className="cate_1">상세보기</span>
                                <img className="more_img" src={iconArrowRight24} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="prod_list">
                    <div className="prod_in">
                        <Link className="unit long" to="#">
                            <img className="com_img" src={prod_01} alt="" />
                            <img className="com_img mo" src={food_03} alt="" />
                        </Link>
                        <div className="prod_grp">
                            <Link className="unit" to="#">
                                <img className="com_img pc" src={prod_02} alt="" />
                                <img className="com_img mo" src={food_03} alt="" />
                            </Link>
                            <Link className="unit" to="#">
                                <img className="com_img pc" src={prod_03} alt="" />
                                <img className="com_img mo" src={food_04} alt="" />
                            </Link>
                        </div>
                        <Link className="unit" to="#">
                            <img className="com_img pc" src={prod_04} alt="" />
                            <img className="com_img mo" src={food_02} alt="" />
                        </Link>
                        <Link className="unit" to="#">
                            <img className="com_img pc" src={prod_05} alt="" />
                            <img className="com_img mo" src={food_01} alt="" />
                        </Link>
                        <Link className="unit" to="#">
                            <img className="com_img pc" src={prod_06} alt="" />
                            <img className="com_img mo" src={food_05} alt="" />
                        </Link>
                        <Link className="unit" to="#">
                            <img className="com_img pc" src={prod_07} alt="" />
                            <img className="com_img mo" src={food_06} alt="" />
                        </Link>
                    </div>
                </div>
            </section>

            <section className="sub_sec sht_t sht_b new_sec">
                <div className="com_inner">
                    <div className="com_tit_con ">
                        <div className="com_tit_unit">
                            <p className="cate_1 green">News</p>
                            <p className="tit_1 green fw600 mb_0">눌러푸드 소식</p>
                        </div>
                        <Link to="/board" className="btn_more com_btn">
                            <span className="cate_1">상세보기</span>
                            <img className="more_img" src={iconArrowRight24} alt="" />
                        </Link>
                    </div>

                    <div className="new_list">
                        <ClientOnly>
                            {() =>
                                data.news &&
                                data.news.map((item) => (
                                    <Link
                                        to={
                                            item.type === 'E'
                                                ? `/board/view/${item.id}?type=event`
                                                : `/board/view/${item.id}?type=news`
                                        }
                                        className="unit"
                                        key={item.id}
                                    >
                                        <div className="new_img">
                                            <img className="img_obj" src={`${WEB_HOST}${item.thumbnailUrl}`} alt="" />
                                        </div>
                                        <div className="txt_con">
                                            <p className="cate_1 green">{item.type === 'E' ? '이벤트' : '보도자료'}</p>
                                            <p className="new_tit tit_4">{item.title}</p>
                                            <p
                                                className="new_txt body_4"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.content,
                                                }}
                                            ></p>
                                            <p className="new_date cate_1">{formatDate(item.audit.modifiedDt!)}</p>
                                        </div>
                                    </Link>
                                ))
                            }
                        </ClientOnly>
                    </div>
                </div>
            </section>

            <section className="sub_sec sht_t ex">
                <div className="pd_05">
                    <div className="com_inner">
                        <div className="com_tit_con ">
                            <div className="com_tit_unit">
                                <p className="cate_1 green">Our Partners</p>
                                <p className="tit_1 green fw600 mb_0">눌러푸드와 함께하는 협력사</p>
                            </div>
                            <Link to="/contact" className="btn_more com_btn">
                                <span className="cate_1">Contact us</span>
                                <img className="more_img" src={iconArrowRight24} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="slide_list">
                    <div className="track animation_slide">
                        <div className="item">
                            <img className="slide_img" src={coop_01} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_02} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_03} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_04} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_05} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_01} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_02} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_03} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_04} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_05} alt="" />
                        </div>
                    </div>
                    <div className="track animation_slide_2">
                        <div className="item">
                            <img className="slide_img" src={coop_01} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_02} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_03} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_04} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_05} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_01} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_02} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_03} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_04} alt="" />
                        </div>
                        <div className="item">
                            <img className="slide_img" src={coop_05} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="sub_sec last sht_t sht_b">
                <div className="com_inner">
                    <div className="last_banner">
                        <div className="banner_inner">
                            <div className="banner_logo">
                                <img className="com_img" src={logo_up} alt="" />
                            </div>
                            <div className="banner_txt_con">
                                <p className="banner_tit tit_2 white fw600">
                                    상상력과 혁신을 통한 새로운 식문화의 리더
                                </p>
                                <p className="banner_copy body_2 white">
                                    눌러푸드는 창의적인 아이디어와 끊임없는 혁신을 통하여 그전에 존재하지 않았던 새로운
                                    식문화를 창조하겠습니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

function MainSlide() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [progress, setProgress] = useState(0);
    const slideInterval = useRef<ReturnType<typeof setInterval> | null>(null);
    const time = 5000;
    const slides = [
        {
            title: 'Convenient',
            description: '세상에서 가장 간편하게 먹을 수 있는 눌러밥을 소개합니다',
            additionalDescription: '눌러밥과 함께하는 최고의 건강 간편식',
        },
        {
            title: 'Creative',
            description: '세상에 없는 새로운 간편식 눌러밥, 맛있고 건강함을 약속드립니다',
            additionalDescription: '신선하고 건강한 식재료와 함께하는 눌러밥',
        },
        {
            title: 'Creditable',
            description: '간편하고 맛있고 건강한 최고의 간편식 눌러밥이 있습니다',
            additionalDescription: '눌러밥과 함께 하는 행복하고 건강한 한 끼',
        },
    ];

    const startSlideShow = () => {
        if (slideInterval.current) return; // Prevent multiple intervals
        slideInterval.current = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
                    return 0;
                }
                return prevProgress + 100 / (time / 100);
            });
        }, 100);
    };

    const stopSlideShow = () => {
        if (slideInterval.current) {
            clearInterval(slideInterval.current);
            slideInterval.current = null;
        }
    };

    const handlePause = () => {
        setIsPaused(!isPaused);
        if (isPaused) {
            startSlideShow();
        } else {
            stopSlideShow();
        }
    };

    const handlePrev = () => {
        stopSlideShow();
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
        setProgress(0);
        startSlideShow();
    };

    const handleNext = () => {
        stopSlideShow();
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
        setProgress(0);
        startSlideShow();
    };

    useEffect(() => {
        startSlideShow();
        return () => stopSlideShow();
    }, []);

    return (
        <section className="main_sec" data-idx="1">
            <div className="slide_text">
                <div className="inner">
                    <div className="tit_list">
                        {slides.map((slide, index) => (
                            <div key={index} className={`tit_unit ${index === currentIndex ? 'on' : ''}`}>
                                <p className="cate_1">{slide.title}</p>
                                <p className="tit_1">{slide.description}</p>
                                <p className="body_2 gray_7">{slide.additionalDescription}</p>
                            </div>
                        ))}
                    </div>

                    <div className="slide_opt">
                        <div className="btn_list">
                            <button className={`slide_btn pause hover ${isPaused ? 'on' : ''}`} onClick={handlePause}>
                                <span className="line"></span>
                                <span className="line"></span>
                            </button>
                            <button className="slide_btn prev" onClick={handlePrev}>
                                <span className="line"></span>
                                <span className="line"></span>
                            </button>
                            <button className="slide_btn next" onClick={handleNext}>
                                <span className="line"></span>
                                <span className="line"></span>
                            </button>
                        </div>

                        <div className="slide_bar">
                            <p className="slide_num slide_now tit_7" data-idx={currentIndex + 1}>
                                0{currentIndex + 1}
                            </p>
                            <div className="slide_back">
                                <div className="slide_act" style={{ width: `${progress}%` }}></div>
                            </div>
                            <p className="slide_num tit_7">03</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="slide">
                {slides.map((_, index) => (
                    <div key={index} className={`unit ${index === currentIndex ? 'on' : ''}`}></div>
                ))}
            </div>

            <div className="down_con">
                <div className="in">
                    <span className="plus_icon">
                        <span className="line"></span>
                        <span className="line"></span>
                    </span>
                    <Link to="#" className="down_unit">
                        <span className="tit">회사 소개서 다운로드</span>
                        <img className="down_icon" src="" alt="" />
                    </Link>
                </div>
            </div>

            <div className="scr_down">
                <div className="in">
                    <div className="src_img">
                        <img className="com_img" src={scroll_down} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}
